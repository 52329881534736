import { HttpError, IResourceComponentsProps, useGetIdentity, useGo, useNotification, useOne, useTranslate, useUpdate } from "@refinedev/core";
import { Avatar, Text, LoadingOverlay, Paper, Group, Button, Grid, Center, Stack, FileButton, Stepper } from "@mantine/core";
import { IOrder, ITalent } from "interfaces";
import { VideoPlayer } from "components/video/videoPlayer";
import { useDisclosure } from "@mantine/hooks";
import { VideoUploader } from "components/video/videoUploader";
import { useModalForm } from "@refinedev/mantine";
import { EditTalentModal } from "components/profile/editTalentModal";
import { IconCamera } from "@tabler/icons";
import { ProfileVideoSelector } from "components/profile/profileVideoSelector";
import { useEffect, useState } from "react";
import { API_URL, FRONTEND_URL, TOKEN_KEY } from "../../constants";
import axios from "axios";

export const ProfileShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { data: identity } = useGetIdentity<{ talentId: number, email: string }>();
    const { data, isLoading } = useOne<ITalent, HttpError>({
        resource: "talents",
        metaData: {
            populate: {
                subcategory: {
                    populate: '*',
                    category: {
                        populate: '*',
                    },
                },
                image: {
                    populate: '*',
                },
                users_permissions_user: {
                    populate: {
                        email: true
                    },
                },
                profileOrders: {
                    populate: '*',
                },
            }
        },
        id: identity?.talentId,
    });
    const talent = data?.data;

    const [opened, handlers] = useDisclosure(false);
    const { open } = handlers

    const [profileVideoSelectionOpened, profileVideoSelectionHandlers] = useDisclosure(false);
    const { open: profileVideoSelectionOpen } = profileVideoSelectionHandlers

    const initialValues = {
        price: talent?.price ?? 0,
        name: talent?.name ?? "",
        description: talent?.description ?? "",
        fastDelivery: talent?.fastDelivery ?? false,
        deliveryDays: talent?.deliveryDays ?? 7,
        fastDeliveryDays: talent?.fastDeliveryDays ?? 1,
        subcategory: talent?.subcategory ?? null
    };

    const editModalForm = useModalForm({
        refineCoreProps: {
            action: "edit", resource: "talents", metaData: {
                populate: {
                    subcategory: {
                        populate: '*',
                        category: {
                            populate: '*',
                        },
                    },
                }
            },
        },
        initialValues,
        validate: {
            price: (value: any) => (value === undefined ? "required" : (value > 500 || value < 5) ? 'must be between 5 and 500' : null),
            name: (value: any) => (value === undefined ? "required" : value.length < 3 ? 'must have at least 3 letters' : null),
            description: (value: any) => (value === undefined ? "required" : value.length < 5 ? 'must have at least 5 letters' : null),
            deliveryDays: (value: any) => (value === undefined ? "required" : (value > 14 || value < 3) ? 'must be between 3 and 14' : null),
            fastDeliveryDays: (value: any) => (value === undefined ? "required" : (value > 3 || value < 1) ? 'must be between 1 and 3' : null),
            subcategory: (value: any) => (value === null ? "required" : null)
        },
    });

    const {
        modal: { show: showEditModal }
    } = editModalForm;

    const { mutate } = useUpdate();
    const activateTalent = () => {
        mutate({
            resource: "talents",
            values: {
                active: true,
            },
            id: identity!.talentId,
            successNotification: () => {
                return {
                    type: "success",
                    message: translate("profile.activatedMessage", "Profile Activated - Your profile is live on BV TV."),
                }
            },
        },);
    }
    const suspendTalent = () => {
        mutate({
            resource: "talents",
            values: {
                active: false,
            },
            id: identity!.talentId,
            successNotification: () => {
                return {
                    type: "success",
                    message: translate("profile.suspendedMessage", "Profile Suspended - Your profile is not visible on BV TV."),
                }
            },
        },);
    }
    const go = useGo();
    const { open: openNotification } = useNotification();
    const requestActivate = () => {
        if (talent?.image === null || talent?.description === null || talent?.price === null || talent?.subcategory === null || talentVideoURL === undefined) {
            openNotification?.({
                type: "error",
                message: translate("profile.requestActivateError", "Edit your information to be able to request activation - Uncomplete profile (image, description, price, intro video)"),
            });
        } else {
            mutate({
                resource: "talents",
                values: {
                    activateRequestAccepted: false,
                },
                id: identity!.talentId,
                successNotification: () => {
                    return {
                        type: "success",
                        message: translate("profile.requestActivateSuccess", "Activation requested - We will email you once your profile can be activated."),
                    }
                },
            });
            go({ to: "/" })
        }
    }

    const [talentVideoURL, setTalentVideoURL] = useState<string | undefined>()

    useEffect(() => {
        setTalentVideoURL(talent?.videoURL ?? undefined);
    }, [talent?.videoURL]);


    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [uploadError, setUploadError] = useState('');

    const handleFileSelection = async (file: File) => {
        try {
            setIsUploadLoading(true)
            const formData = new FormData();
            const { name } = file;

            formData.append("files", file, name);

            const res = await axios.post(
                `${API_URL}/api/upload`,
                formData,
                {
                    withCredentials: false,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            TOKEN_KEY,
                        )}`,
                    },
                },
            );
            if (res.data[0] !== null) {
                mutate({
                    resource: "talents",
                    values: {
                        image: [res.data[0]],
                    },
                    id: identity!.talentId,
                    successNotification: () => {
                        return {
                            type: "success",
                            message: translate("profile.pictureUpdatedSuccess", "Profile picture updated"),
                        }
                    },
                })
            } else {
                setUploadError(translate("profile.pictureUpdatedError", "Upload failure. Please try again."));
            }
            setIsUploadLoading(false);
        } catch (error) {
            setUploadError(translate("profile.pictureUpdatedError", "Upload failure. Please try again."));
            setIsUploadLoading(false);
        }
    }

    return (<Center>
        <LoadingOverlay visible={isLoading ?? false} overlayOpacity={1} />
        <Grid justify="center" maw={800}>
            <Grid.Col order={talent?.active === true ? 1 : 0}>
                <Paper shadow="xs" p="md">
                    {talent?.activateRequestAccepted === null && (talent?.image === null || talent?.description === null || talent?.price === null || talent?.videoURL === null) &&
                        <Stepper active={0} orientation="vertical" size="md">
                            <Stepper.Step label={translate("dashboard.boarding.setup", "Setup Profile")} description={translate("profile.complete", "Edit talent information, upload a profile picture and record introduction video to complete")} />
                        </Stepper>
                    }
                    {talent?.activateRequestAccepted === null && talent?.image && talent?.description && talent?.price && talentVideoURL &&
                        <Group position="apart">
                            <Stepper active={1} orientation="vertical" size="md">
                                <Stepper.Step hidden={true} />
                                <Stepper.Step label={translate("dashboard.boarding.request", "Request Activation from BV TV team")} description={translate("dashboard.boarding.requestDesc", "Your profile looks all good")} />
                            </Stepper>
                            <Button px={20} onClick={requestActivate}>
                                {translate("profile.requestActivation", "Request Activation")}
                            </Button>
                        </Group>
                    }
                    {talent?.activateRequestAccepted === false &&
                        <Stepper active={2} orientation="vertical" size="md">
                            <Stepper.Step hidden={true} />
                            <Stepper.Step hidden={true} />
                            <Stepper.Step label={translate("dashboard.boarding.wait", "Wait for BV TV team reply")} description={translate("dashboard.boarding.waitDesc", "Your profile will be reviewed")} />
                        </Stepper>
                    }
                    {talent?.active &&
                        <Group>
                            <Text size="sm" pr={10}>
                                {translate("profile.active", "Status: Active")}
                            </Text>
                            <Button variant="subtle" size="xs" pt={1} compact onClick={suspendTalent}>
                                {translate("profile.suspend", "Suspend activity")}
                            </Button>
                        </Group>
                    }
                    {talent?.activateRequestAccepted && !talent?.active &&
                        <Group>

                            <Text size="sm" pr={10}>
                                {translate("profile.inactive", "Status: Inactive")}
                            </Text>
                            <Button px={20} onClick={activateTalent}>
                                {translate("profile.activate", "Activate")}
                            </Button>
                        </Group>
                    }
                </Paper>
            </Grid.Col>
            <Grid.Col xs={6}>
                <Paper shadow="xs" p="md">
                    <Group position="apart">
                        <Text size="sm" >
                            {translate("profile.talentInformation", "Talent Information")}
                        </Text>
                        <Button variant="outline" px={20} onClick={() => showEditModal(identity?.talentId)}>
                            {translate("profile.edit", "Edit")}
                        </Button>
                    </Group>
                    <Stack spacing={0} pl={30}>
                        <Text weight={600} size="md" truncate pt={10}>{talent?.name ?? "Name not set"}</Text>
                        <Text size="sm" color="dimmed" truncate>{talent?.description ?? "Description not set"}</Text>
                        <Text weight={400} size="sm" >
                            {translate("profile.category", "Category")}: {translate(`subcategories.${talent?.subcategory?.name}`, talent?.subcategory?.name)}
                        </Text>
                    </Stack>
                    <Group position="apart" pt={20}>
                        <Text size="sm" >
                            {translate("profile.listingDetails", "Listing Details")}
                        </Text>
                    </Group>
                    <Stack spacing={0} pl={30}>
                        <Text weight={600} size="md" pt={10}>{translate("profile.price", "Price")}: €{talent?.price ?? ` - ${translate("profile.priceNotSet", "Price not set")}`}</Text>
                        <Text weight={400} size="sm" >{translate("profile.delivery", "Delivery")}: {translate("profile.within", "within")} {talent?.deliveryDays ?? 7} {translate("profile.days", "days")}</Text>
                        {!talent?.fastDelivery &&
                            <Text weight={200} size="sm">
                                {translate("profile.noFastDelivery", "Fast delivery is not enbled")}
                            </Text>
                        }
                        {talent?.fastDelivery &&
                            <Text weight={400} size="sm">
                                {translate("profile.fastDelivery", "Fast delivery")}: {translate("profile.within", "within")} {talent?.fastDeliveryDays ?? 1} {talent?.fastDeliveryDays > 1 ? translate("profile.days", "days") : translate("profile.days", "day")}
                            </Text>
                        }
                    </Stack>

                    <Group position="apart" pt={20}>
                        <Text size="sm">
                            {translate("profile.picture", "Profile Picture")}
                        </Text>
                        <FileButton onChange={handleFileSelection} accept="image/png,image/jpeg" >
                            {(props) => <Button {...props} variant="outline" loading={isUploadLoading}>
                                {translate("profile.uploadNew", "Upload new")}
                            </Button>}
                        </FileButton>
                    </Group>
                    <Center>
                        <Stack>
                            {uploadError && <Text size="xs" style={{ color: 'red' }}>{uploadError}</Text>}
                            <Avatar
                                pt={10}
                                w={300 * 0.75}
                                h={300}
                                variant="filled"
                                src={talent?.image !== null ? talent?.image[0].url : ""}
                            />
                        </Stack>
                    </Center>
                </Paper>
            </Grid.Col>
            <Grid.Col xs={6}>
                <Paper shadow="xs" p="md" mih={480} >
                    <Group position="apart" pb={20}>
                        <Text size="sm">
                            {translate("profile.introduction", "Introduction")}
                        </Text>
                        <Button variant="outline" px={20} onClick={open}>
                            {translate("profile.record", "Record video")}
                        </Button>
                    </Group>
                    <Center>
                        {talentVideoURL !== undefined
                            ? <VideoPlayer videoURL={talentVideoURL} />
                            : <Stack h={300} pt={50} align="center"><IconCamera size={150} /><Text>{translate("profile.needsToBeRecorded", "Needs to be recorded")}</Text></Stack>
                        }
                    </Center>
                </Paper>
            </Grid.Col>
            <Grid.Col>
                <Paper shadow="xs" p="md">
                    <Group position="apart" pb={20}>
                        <Text size="sm" >{translate("profile.featured", "Featured videos")}</Text>
                        <Button variant="outline" px={20} onClick={profileVideoSelectionOpen}>{translate("profile.manage", "Manage")}</Button>
                    </Group>
                    <Center>
                        <Stack spacing={0}>
                            <Group position="center">
                                {talent?.profileOrders.map((order: IOrder) => {
                                    if (order.videoURL) {
                                        return (
                                            <VideoPlayer videoURL={order.videoURL} />
                                        )
                                    }
                                    return null
                                })}
                                {talent?.profileOrders?.length === 0 &&
                                    <Text size="sm" mih={100} pt={30}>
                                        {translate("profile.noFeatured", "After your first deliveries, you will be able to select certain videos of past deliveries to show on your profile page.")}
                                    </Text>
                                }
                            </Group>
                        </Stack>
                    </Center>
                </Paper>
            </Grid.Col>
            <Grid.Col order={talent?.active === true ? 1 : 0}>
                <Paper shadow="xs" p="md">
                    <Group>
                        {talent?.activateRequestAccepted &&
                            <Button px={20} variant="outline" compact onClick={() => window.open(`${FRONTEND_URL}/${talent?.slug}`, '_blank')}>
                                {translate("profile.visit", "Visit your booking page")}
                            </Button>
                        }
                    </Group>
                </Paper>
                <Paper shadow="xs" p="md">
                    <Text size="md" >{translate("profile.contact", "Your contact information")}:</Text>
                    <Group>
                        <Text size="sm">
                            <i>{translate("profile.email", "Email")}:</i> {identity?.email ?? translate("profile.notSet", "Not set")}
                        </Text>
                        <Text size="sm">
                            <i>{translate("profile.mobile", "Mobile")}:</i> {talent?.mobileNumber ?? translate("profile.notSet", "Not set")}
                        </Text>
                        <Text size="sm">
                            <i>{talent?.socialPlatform ?? ""}:</i> {talent?.socialLinks ?? translate("profile.notSet", "Not set")}
                        </Text>
                    </Group>
                </Paper>
            </Grid.Col>
        </Grid >
        <EditTalentModal {...editModalForm} />
        <VideoUploader
            apiResource="talents"
            apiID={talent?.id!}
            successMessage={translate("profile.introductionChanged", "Introduction video has been changed")}
            opened={opened}
            handlers={handlers}
        />
        <ProfileVideoSelector opened={profileVideoSelectionOpened} handlers={profileVideoSelectionHandlers} talent={talent} />
    </Center>)
};
