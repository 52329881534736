import React from "react";
import {
  RegisterPageProps,
  RegisterFormTypes,
  useTranslate,
  useRouterContext,
  useRouterType,
  useLink,
  useRegister,
  useGo,
} from "@refinedev/core";
import { FormContext, ThemedTitleV2 } from "@refinedev/mantine";
import { FormPropsType } from "../index";
import {
  layoutStyles,
  cardStyles,
  titleStyles,
  pageTitleStyles,
} from "./styles";
import {
  Box,
  Card,
  PasswordInput,
  Space,
  TextInput,
  Title,
  Anchor,
  Button,
  Text,
  BoxProps,
  CardProps,
  Group,
  Stack,
  Divider,
  useMantineTheme,
  Select,
} from "@mantine/core";
import { AppIcon } from "components/layout/appIcon";
import { LanguageMenu } from "components/layout/languageMenu";

type RegisterProps = RegisterPageProps<BoxProps, CardProps, FormPropsType>;

/**
 * The register page will be used to register new users. You can use the following props for the <AuthPage> component when the type is "register".
 * @see {@link https://refine.dev/docs/api-reference/mantine/components/mantine-auth-page/#register} for more details.
 */
export const RegisterPage: React.FC<RegisterProps> = ({
  loginLink,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
  providers,
  title,
}) => {
  const theme = useMantineTheme();
  const { useForm, FormProvider } = FormContext;
  const { onSubmit: onSubmitProp, ...useFormProps } = formProps || {};
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;


  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      name: (value: any) => (value === undefined ? "required" : value.length < 3 ? 'must have at least 3 letters' : null),
      mobileNumber: (value: any) => (value === undefined ? "required" : value.length < 6 ? 'Too short' : null),
      socialLinks: (value: any) => (value === undefined ? "required": null),
      socialPlatform: (value: any) => (value === undefined ? "required": null),
      email: (value: any) =>
        /^\S+@\S+$/.test(value)
          ? null
          : translate(
            "register.invalidEmail",
            "Invalid email address"
          ),
      password: (value: any) => {
        if (!value) {
          return translate(
            "register.passwordRequired",
            "Password is required"
          );
        }
        if (value.length < 8) {
          return translate(
            "register.passwordLenght",
            "Password must be at least 8 characters long"
          );
        }
        if (!/[A-Z]/.test(value)) {
          return translate(
            "register.passwordContainLetter",
            "Password must contain at least one uppercase letter"
          );
        }
        if (!/\d/.test(value)) {
          return translate(
            "register.passwordContainNumber",
            "Password must contain at least one number"
          );
        }
        return null;
      },
    },
    ...useFormProps,
  });
  const { onSubmit, getInputProps } = form;

  const { mutate: register, isLoading } = useRegister<RegisterFormTypes>();

  const PageTitle =
    title === false ? null : (
      <div style={pageTitleStyles}>
        {title ?? <ThemedTitleV2 icon={<AppIcon />} text={translate("common.title", "BV TV Studio")} collapsed={false} />}
      </div>
    );
  const go = useGo();

  const CardContent = (
    <Card style={cardStyles} {...(contentProps ?? {})} >
      <Title
        style={titleStyles}
        color={theme.colorScheme === "dark" ? "brand.5" : "brand.8"}
      >
        {translate("register.join", "Join as Talent")}
      </Title>
      <Space h="sm" />
      <Space h="lg" />
      <FormProvider form={form}>
        <form
          onSubmit={onSubmit((values: any) => {
            if (onSubmitProp) {
              return onSubmitProp(values);
            }
            return register(values,
              {
                onSuccess: (data) => {
                  if (data.success) {
                    /*open?.({
                      type: "success",
                      message: "Thanks for registering - Our team will review your request soon and email you with further information.",
                    });*/
                    go({ to: "/under-review" })
                  }
                },
              },              
            );
          })}
        >
          <TextInput withAsterisk
            label={translate("register.name", "Full name")}
            placeholder={translate("register.namePlaceholder", "ie.: John Doe")}
            {...getInputProps("name")}
          />
          <TextInput mt="xs" withAsterisk
            label={translate("register.mobile", "Mobile")}
            placeholder={translate("register.mobilePlaceholder", "+123456789")}
            {...getInputProps("mobileNumber")}
          />
          <Select mt="xs" withAsterisk
            data={['Facebook', 'Instagram', 'TikTok', 'Twitter', 'YouTube']}
            placeholder={translate("register.socialPlaceholder", "Pick one")}
            label={translate("register.social", "Social platform (with most followers)")}
            {...getInputProps("socialPlatform")}
          />
          <TextInput mt="xs" withAsterisk
            label={translate("register.socialAccount", "Social account name")}
            placeholder={translate("register.socialAccountPlaceholder", "@johndoe")}
            {...getInputProps("socialLinks")}
          />
          <Divider my="sm" />
          <TextInput mt="md" withAsterisk
            label={translate("register.email", "Email")}
            placeholder={translate("register.emailPlaceholder", "john@email.com")}
            {...getInputProps("email")}
          />
          <PasswordInput mt="xs" withAsterisk
            label={translate("register.password", "Password")}
            placeholder="●●●●●●●●"
            {...getInputProps("password")}
          />
          <Button mt="lg" fullWidth size="md" type="submit" loading={isLoading}>
            {translate("register.signup", "Sign up")}
          </Button>

          {loginLink ?? (
            <Group mt="md" position="center">
              <Text size="xs">
                {translate(
                  "register.haveAccount",
                  "Have an account?"
                )}{" "}
                <Anchor component={ActiveLink as any} to="/login" weight={700}>
                  {translate("register.signin", "Sign in")}
                </Anchor>
              </Text>
            </Group>
          )}
        </form>
      </FormProvider>
    </Card>
  );

  return (
    <Box style={layoutStyles} {...(wrapperProps ?? {})}>
      {renderContent ? (
        renderContent(CardContent, PageTitle)
      ) : (
        <Group spacing={5} position="center" my={20} mx={50}>
          <Box px={{ base: 0, xs: 40 }} pb={5}>
            <Stack align="center">
              <LanguageMenu />
              {PageTitle}
            </Stack>
          </Box>
          {CardContent}
        </Group>
      )}
    </Box>
  );
};
