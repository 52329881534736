import { BaseRecord, HttpError, useList, useTranslate } from "@refinedev/core";
import { UseModalFormReturnType, SaveButton } from "@refinedev/mantine";
import { Modal, TextInput, Box, Text, Checkbox, NumberInput, Select, Card } from "@mantine/core";
import React from "react";
import { ISubcategory } from "interfaces";

interface FormValues {
    price: number
    name: string
    description: string
    fastDelivery: boolean
    deliveryDays: number
    fastDeliveryDays: number
    subcategory: ISubcategory | null,
}

export const EditTalentModal: React.FC<UseModalFormReturnType<BaseRecord, HttpError, FormValues>> = ({
    getInputProps,
    refineCore: { queryResult },
    errors,
    modal: { visible, close },
    saveButtonProps }) => {

    const translate = useTranslate();         
    const talentsData = queryResult?.data?.data;
    const { data: subcategoryData } = useList<ISubcategory, HttpError>({
        resource: "subcategories",
        metaData: {
            populate: ["category"]
        }
    });
    const subcategorySelectData = subcategoryData?.data.map(item => ({ label: translate(`subcategories.${item.name}`, item.name), value: String(item.id), group: translate(`categories.${item.category.name}`, item.category.name) })) ?? []
    const subcategoryValue = String(getInputProps("subcategory").value?.id) || null;
    const handleSubcategoryChange = (selectedSubcategory: any) => {
        getInputProps("subcategory").onChange({ id: selectedSubcategory })
    }

    const fastDeliveryDaysValue = String(getInputProps("fastDeliveryDays").value) || null;

    return (
        <Modal opened={visible} onClose={close} title={<React.Fragment />} >
            <Card withBorder shadow="md" px={30} radius="md">
                <TextInput
                    mt={8}
                    id="name"
                    label={translate("profile.fullName","Full name")}
                    placeholder={translate("profile.fullNamePlaceholder","ie.: John Doe")}
                    {...getInputProps("name")}
                    withAsterisk
                />
                <TextInput
                    mt={8}
                    id="description"
                    label={translate("profile.description","Description")}
                    placeholder={translate("profile.descriptionPlaceholder","ie.: The coolest guy in town")}
                    {...getInputProps("description")}
                    withAsterisk
                />
                <Select
                    mt="sm"
                    label={translate("profile.category","Category")}
                    placeholder={translate("profile.categoryPlaceholder","Select category")}
                    //{...selectProps}
                    //{...getInputProps("subcategory.id")}
                    value={subcategoryValue}
                    data={subcategorySelectData}
                    onChange={handleSubcategoryChange}
                    defaultValue={String(talentsData?.subcategory?.id)}
                    withAsterisk
                />
            </Card>
            <Card withBorder shadow="md" px={30} mt={20} radius="md">

                <NumberInput
                    mt={8}
                    id="price"
                    label={translate("profile.price","Price")}
                    description={translate("profile.priceDesc","Talents typically request prices based on the complexity and length of the video. In general it is between €10 and €50.")}
                    {...getInputProps("price")}
                    withAsterisk
                />
                <NumberInput
                    mt={28}
                    id="deliveryDays"
                    label={translate("profile.deliveryDays","Delivery days")}
                    description={translate("profile.deliveryDaysDesc","The number of days in which you promise to deliver the video after the user places an order.")}
                    {...getInputProps("deliveryDays")}
                    withAsterisk
                />
                <Checkbox
                    mt={18}
                    id="fastDelivery"
                    label={translate("profile.fastDelivery","Fast delivery")}
                    {...getInputProps("fastDelivery", { type: 'checkbox' })}
                    description={translate("profile.fastDeliveryDesc","Talents can charge more for a fast delivery: either within 24h (30% higher pay), 48h (20% higher pay) or 72h (10% higher pay).")}
                />
                <Select
                    mt={8}
                    id="fastDeliveryDays"
                    label={translate("profile.fastDeliveryTime","Fast delivery time")}
                    data={[{ value: '1', label: `24 ${translate("profile.hours","hours")} (30% ${translate("profile.higherPay","higher pay")})`}, { value: '2', label: `48 ${translate("profile.hours","hours")} (20% ${translate("profile.higherPay","higher pay")})`}, { value: '3', label: `72 ${translate("profile.hours","hours")} (10% ${translate("profile.higherPay","higher pay")})`}]}
                    value={fastDeliveryDaysValue}
                    onChange={(value) => {
                        if (typeof value === 'string') {
                            getInputProps("fastDeliveryDays").onChange(parseInt(value, 10))
                        }
                    }}
                    defaultValue={String(talentsData?.fastDeliveryDays.value)}
                />       
            </Card>
            {errors.content && (
                <Text mt={2} weight={500} size="xs" color="red">
                    {errors.content}
                </Text>
            )}
            <Box mt={28} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <SaveButton {...saveButtonProps} />
            </Box>

        </Modal>
    );
};