import { AuthBindings } from "@refinedev/core";
import { AuthHelper } from "@refinedev/strapi-v4";


import { API_URL, TOKEN_KEY } from "./constants";

import axios from "axios";

export const axiosInstance = axios.create();
const strapiAuthHelper = AuthHelper(API_URL + "/api");

export const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    try {
    const { data, status } = await strapiAuthHelper.login(email, password);
    if (status === 200) {
      localStorage.setItem(TOKEN_KEY, data.jwt);

      // set header axios instance
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.jwt}`;

      const { data: userData } = await strapiAuthHelper.me(data.jwt, {
        metaData: {
          populate: ["role"],
        },
      });
      const roleType = userData.role.type
      if (roleType !== "talentrole") {
        localStorage.removeItem(TOKEN_KEY);
        return Promise.reject({
          name: "The email or password that you've entered doesn't match any talent account.",
        });
      }

      const { data: talentData } = await axios.get(
        `${API_URL}/api/talents?filters[users_permissions_user][id][$eq]=${userData.id}&fields[0]=enrollAccepted`,
        { headers: { Authorization: `Bearer ${data.jwt}` } }
      );

      if (talentData?.data[0].attributes.enrollAccepted === false) {
        localStorage.removeItem(TOKEN_KEY);
        return Promise.reject({
          name: "Registration needs to be accepted by our team. You'll receive an email about it.",
        });
      }

      return {
        success: true
      }
    }
  } catch (error) {
    console.error(error)
  }


    return {
      success: false,
    };
},
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  onError: async (error) => {
    if (error && error.statusCode === 401) {
      return {
          logout: true,
          redirectTo: "/login",
    };
    }
    return Promise.reject({
      name: "Login failed",
    });
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      error: {
        message: "Authentication failed",
        name: "Token not found",
      },
      logout: true,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return null;
    }

    const { data, status } = await strapiAuthHelper.me(token);
    if (status === 200) {
      const { id, username, email } = data;

      const getQuery = `${API_URL}/api/talents?filters[users_permissions_user][id][$eq]=${id}&fields[0]=id`
      const response = await axios.get(
        getQuery,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const talentId = response.data.data.length > 0 ? response.data.data[0].id : undefined

      return {
        id,
        name: username,
        email,
        talentId
      };
    }
    return {
      authenticated: false,
      error: {
        message: "Authentication failed",
        name: "Token not found",
      },
      logout: true,
      redirectTo: "/login",
    };
  },

  forgotPassword: async ({ email }) => {
    const {success} = await axios
      .post(`${API_URL}/api/auth/forgot-password`, {
        email: email,
      })
      .then(response => {
        return {
          success: true
        }
      })
      .catch(error => {
        return {
          success: false
        }
      });
    return {
        success: success
    }
  },
  updatePassword: async ({ password, confirmPassword, code }) => {
    const {success} = await axios
    .post(`${API_URL}/api/auth/reset-password`, {
      code: code,
      password: password,
      passwordConfirmation: confirmPassword,
    })
    .then(response => {
      return {
        success: true,
        redirectTo: "/",
      }
    })
    .catch(error => {
      console.error(error)
      return {
        success: false
      }
    });    
    return {
      success: success,
      redirectTo: success ? "/" : "",
    }
  },
  register: async ({ email, password, providerName, name, price, mobileNumber, socialLinks, socialPlatform }) => {
    try {
      const { data: userData, status } = await axios.post(`${API_URL}/api/auth/local/register`, {
        username: email,
        email: email,
        password: password,
      })
      if (status !== 200 || userData.lenght === 0) {
        return {
          success: false
        }
      }
      await axios.post(`${API_URL}/api/talents`, {
        data:
        {
          name: name,
          price: price,
          mobileNumber: mobileNumber,
          socialLinks: socialLinks,
          socialPlatform: socialPlatform,
          users_permissions_user: { id: userData.user.id }
        }
      })
    } catch (error) {
      console.error(error);
      return {
        success: false
      }
    }
    return {
      success: true,
    }
  },
};
