import React from "react";
import {
  LoginPageProps,
  LoginFormTypes,
  useRouterType,
  useLink,
  useActiveAuthProvider,
  useLogin,
  useTranslate,
  useRouterContext,
} from "@refinedev/core";
import { FormContext, ThemedTitleV2 } from "@refinedev/mantine";
import { FormPropsType } from "../index";
import {
  layoutStyles,
  cardStyles,
  pageTitleStyles,
  titleStyles,
} from "./styles";
import {
  Box,
  Card,
  Checkbox,
  PasswordInput,
  TextInput,
  Anchor,
  Button,
  Text,
  Stack,
  BoxProps,
  CardProps,
  Group,
  Space,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { AppIcon } from "components/layout/appIcon";
import { LanguageMenu } from "components/layout/languageMenu";

type LoginProps = LoginPageProps<BoxProps, CardProps, FormPropsType>;

/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 * @see {@link https://refine.dev/docs/api-reference/mantine/components/mantine-auth-page/#login} for more details.
 */
export const LoginPage: React.FC<LoginProps> = ({
  registerLink,
  forgotPasswordLink,
  rememberMe,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
  title,
}) => {
  const theme = useMantineTheme();
  const { useForm, FormProvider } = FormContext;
  const { onSubmit: onSubmitProp, ...useFormProps } = formProps || {};
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validate: {
      email: (value: any) =>
        /^\S+@\S+$/.test(value)
          ? null
          : translate("pages.login.errors.validEmail", "Invalid email address"),
      password: (value: any) => value === "",
    },
    ...useFormProps,
  });
  const { onSubmit, getInputProps } = form;

  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<LoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const PageTitle =
    title === false ? null : (
      <div style={pageTitleStyles}>
        {title ?? <ThemedTitleV2 icon={<AppIcon />} text={translate("common.title", "BV TV Studio")} collapsed={false} />}
      </div>
    );


  const CardContent = (
    <Card style={cardStyles} {...(contentProps ?? {})}>
      <Title
        style={titleStyles}
        color={theme.colorScheme === "dark" ? "brand.5" : "brand.8"}
      >
        {translate("login.welcome", "Welcome")}
      </Title>
      <Space h="sm" />
      <FormProvider form={form}>
        <form
          onSubmit={onSubmit((values: any) => {
            if (onSubmitProp) {
              return onSubmitProp(values);
            }
            return login(values);
          })}
        >
          <TextInput
            name="email"
            label={translate("login.email", "Email")}
            placeholder={translate("login.email", "Email")}
            {...getInputProps("email")}
          />
          <PasswordInput
            name="password"
            mt="md"
            label={translate("login.password", "Password")}
            placeholder="●●●●●●●●"
            {...getInputProps("password")}
          />
          <Box
            mt="md"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {rememberMe ?? (
              <Checkbox
                label={translate("login.remember","Remember me")}
                size="xs"
                {...getInputProps("remember", {
                  type: "checkbox",
                })}
              />
            )}
            {forgotPasswordLink ?? (
              <Anchor
                component={ActiveLink as any}
                to="/forgot-password"
                size="xs"
              >
                {translate(
                  "login.forgotPassword",
                  "Forgot password?"
                )}
              </Anchor>
            )}
          </Box>
          <Button mt="md" fullWidth size="md" type="submit" loading={isLoading}>
            {translate("login.signin", "Sign in")}
          </Button>
        </form>
      </FormProvider>

      {registerLink ?? (
        <Text mt="md" size="xs" align="center">
          {translate("login.noAccount", "Don’t have an account?")}{" "}
          <Anchor component={ActiveLink as any} to="/register" weight={700}>
            {translate("login.signup", "Sign up")}
          </Anchor>
        </Text>
      )}
    </Card>
  );

  return (
    <Box style={layoutStyles} {...(wrapperProps ?? {})}>
      {renderContent ? (
        renderContent(CardContent, PageTitle)
      ) : (
        <Group spacing={5} position="center" my={20} mx={50}>
          <Box px={{ base: 0, xs: 40 }} pb={5}>
            <Stack align="center">
              <LanguageMenu />
              {PageTitle}
            </Stack>
          </Box>
          {CardContent}
        </Group>
      )}
    </Box>
  );
};
