import { Avatar, Menu } from "@mantine/core";
import { useGetLocale, useSetLocale, useTranslate } from "@refinedev/core";
import React from "react";

export const LanguageMenu: React.FC = () => {
  const translate = useTranslate();
  const changeLanguage = useSetLocale();
  const locale = useGetLocale();
  const currentLocale = locale();

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Avatar
          src={`/images/flags/${currentLocale}.svg`}
          size={26}
          radius="lg"
        />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          key="nl"
          onClick={() => {
            changeLanguage("nl");
          }}
          value={"nl"}
          color={"nl" === currentLocale ? "primary" : undefined}
          icon={
            <Avatar
              src={`/images/flags/nl.svg`}
              size={18}
              radius="lg"
            />
          }
        >
          {translate("common.nl", "Flemish")}
        </Menu.Item>
        <Menu.Item
          key="fr"
          onClick={() => {
            changeLanguage("fr");
          }}
          value={"fr"}
          color={"fr" === currentLocale ? "primary" : undefined}
          icon={
            <Avatar
              src={`/images/flags/fr.svg`}
              size={18}
              radius="lg"
            />
          }
        >
          {translate("common.fr", "French")}
        </Menu.Item>
        <Menu.Item
          key="en"
          onClick={() => {
            changeLanguage("en");
          }}
          value={"en"}
          color={"en" === currentLocale ? "primary" : undefined}
          icon={
            <Avatar
              src={`/images/flags/en.svg`}
              size={18}
              radius="lg"
            />
          }
        >
          {translate("common.en", "English")}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}