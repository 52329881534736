import { Authenticated, Refine } from "@refinedev/core";
import { ErrorComponent, notificationProvider, RefineThemes, ThemedLayoutV2, ThemedTitleV2, } from "@refinedev/mantine";
import { ColorScheme, ColorSchemeProvider, Global, MantineProvider, } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { NotificationsProvider } from "@mantine/notifications";
import routerBindings, { CatchAllNavigate, NavigateToResource, UnsavedChangesNotifier, } from "@refinedev/react-router-v6";
import { DataProvider } from "@refinedev/strapi-v4";
import { AppIcon } from "components/layout/appIcon";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider, axiosInstance } from "./authProvider";
import { Header } from "./components/layout/header";
import { API_URL } from "./constants";
import { OrderEdit, OrderList } from "pages/orders";
import { Dashboard } from "pages/dashboard";
import React from "react";
import { ProfileShow } from "pages/profile";
import { AuthPage } from "components/pages/auth";
import { UnderReviewPage } from "components/pages/auth/components/underreview";

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "dark",
    getInitialValueInEffect: true,
  });
  const { t, i18n } = useTranslation();

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

    const i18nProvider = {
      translate: (key: string, params: object) => t(key, params),
      changeLocale: (lang: string) => i18n.changeLanguage(lang),
      getLocale: () => i18n.language,
    };

  return (
    <BrowserRouter>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          theme={{ ...RefineThemes.Orange, colorScheme: colorScheme }}
          withNormalizeCSS
          withGlobalStyles
        >
          <Global styles={{ body: { WebkitFontSmoothing: "auto", maxWidth: '100vw' } }} />
          <NotificationsProvider position="top-right">
            <Refine
              authProvider={authProvider}
              dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
              notificationProvider={notificationProvider}
              i18nProvider={i18nProvider}
              routerProvider={routerBindings}
              resources={[
                {
                  name: "dashboard",
                  list: "/dashboard",
                },                
                {
                  name: "orders",
                  list: "/orders",
                  edit: "/orders/edit/:id",
                },
                {
                  name: "profile",
                  list: "/profile",
                  edit: "/profile/edit",
                },

              ]}
              options={{
                disableTelemetry: true,
                //syncWithLocation: true,
                warnWhenUnsavedChanges: true,
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated
                      fallback={<CatchAllNavigate to="/login" />}
                    >
                      <ThemedLayoutV2
                        Header={() => ( <Header isSticky={true} /> )}
                        Sider={() => <React.Fragment />}
                        Title={({ collapsed }) => (
                          <ThemedTitleV2
                            collapsed={collapsed}
                            text="BV TV Studio"
                            icon={<AppIcon />}
                            wrapperStyles={{whiteSpace: 'nowrap'}}
                          />
                        )}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route
                    index
                    element={<NavigateToResource resource="dashboard" />}
                  />
                  <Route path="/dashboard">
                    <Route index element={<Dashboard />} />
                  </Route>
                  <Route path="orders">
                    <Route index element={<OrderList />} />
                    <Route path="edit/:id" element={<OrderEdit />} />
                  </Route>
                  <Route path="profile">
                    <Route index element={<ProfileShow />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <Authenticated fallback={<Outlet />}>
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route
                    path="/login"
                    element={
                      <AuthPage
                        type="login"
                        title={
                          <ThemedTitleV2
                            collapsed={false}
                            text="BV TV Studio"
                            icon={<AppIcon />}
                          />
                        }
                      />
                    }
                  />
                  <Route
                    path="/register"
                    element={<AuthPage type="register" />}
                  />
                  <Route
                    path="/forgot-password"
                    element={<AuthPage type="forgotPassword" />}
                  />
                  <Route
                    path="/update-password"
                    element={<AuthPage type="updatePassword" />}
                  />
                  <Route
                    path="/under-review"
                    element={<UnderReviewPage />}
                  />
                </Route>
                <Route
                  element={
                    <Authenticated>
                      <ThemedLayoutV2
                        Header={() => ( <Header isSticky={true} /> )}
                        Title={({ collapsed }) => (
                          <ThemedTitleV2
                            collapsed={collapsed}
                            text="BV TV Studio"
                            icon={<AppIcon />}
                          />
                        )}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
              </Routes>
              <UnsavedChangesNotifier />
            </Refine>
          </NotificationsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </BrowserRouter>
  );
}

export default App;
