import { Button, Checkbox, LoadingOverlay, Modal, Paper, SimpleGrid, Stack, Text } from "@mantine/core";
import { useInfiniteList, useNotification, useTranslate, useUpdate } from "@refinedev/core";
import { VideoPlayer } from "components/video/videoPlayer";
import { IOrder, ITalent } from "interfaces";
import React, { useEffect, useState } from "react";

interface ProfileVideoSelectorProps {
    talent: ITalent | undefined
    opened: boolean
    handlers: {
        open: () => void;
        close: () => void;
        toggle: () => void;
    }
}

export const ProfileVideoSelector: React.FC<ProfileVideoSelectorProps> = ({ opened, handlers, talent }) => {
    const translate = useTranslate();
    const { close } = handlers
    const {
        data,
        isLoading,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteList<IOrder>({
        resource: "orders",
        sorters: [
            {
                field: "createdAt",
                order: "desc",
            },
        ],
        filters: [
            {
                field: "talent.id",
                operator: "eq",
                value: talent?.id !== undefined ? talent?.id : 0,
            },
            {
                field: "status",
                operator: "eq",
                value: "complete",
            },
        ],
        pagination: {
            pageSize: 4,
        },
    });
    const allPages: IOrder[] = ([] as IOrder[]).concat(...(data?.pages ?? []).map((page) => page.data));

    const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([])

    useEffect(() => {
        setSelectedOrderIds(talent?.profileOrders.map((order: IOrder) => order.id) ?? []);
    }, [talent?.profileOrders]);

    const { open: openNotification } = useNotification();

    const handleSelection = (orderId: string) => {
        if (selectedOrderIds.includes(orderId)) {
            setSelectedOrderIds(selectedOrderIds.filter(id => id !== orderId));
        } else {
            if (selectedOrderIds.length === 3) {
                openNotification?.({
                    type: "error",
                    message: translate("profile.featured3allowed", "Only 3 videos allowed - You need to unselect one of the selected videos and choose a new one"),
                });
                return
            }
            setSelectedOrderIds([...selectedOrderIds, orderId]);
        }
    };

    const { mutate } = useUpdate();
    const updateProfileVideos = () => {
        mutate({
            resource: "talents",
            values: {
                profileOrders: selectedOrderIds,
            },
            id: talent?.id!,
            successNotification: () => {
                return {
                    message: translate("profile.featuredSuccess", "Hurray - Selected videos can be seen on booking page"),
                    type: "success",
                }
            },
        });
        close()
    }

    return (
        <Modal
            opened={opened}
            onClose={close}
            title={allPages.length === 0 ? "": translate("profile.featuredSelect","Select videos (up to 3)")}
            padding={20}
            size="auto"
            trapFocus={false}
        >
            <Stack align="center" justify="flex-start">
                <LoadingOverlay visible={isLoading ?? false} overlayOpacity={1} />
                {allPages.length > 0 && <>
                    <Button variant="outline" onClick={() => { updateProfileVideos() }}>
                        {translate("profile.featuredConfirm","Confirm selection")}
                    </Button>
                    <SimpleGrid
                        breakpoints={[
                            { minWidth: 250, cols: 1 },
                            { minWidth: 500, cols: 2 },
                            { minWidth: 750, cols: 3 },
                            { minWidth: 1000, cols: 4 },
                        ]}
                    >
                        {allPages.map((order) => (
                            <Paper shadow="xs" radius="lg" p="xs" withBorder>
                                <Stack align="center">
                                    <Checkbox label="" radius="md" size="xl"
                                        checked={selectedOrderIds.includes(order.id)}
                                        onChange={() => { handleSelection(order.id) }}
                                    />
                                    <VideoPlayer videoURL={order.videoURL!} height={350} />
                                </Stack>
                            </Paper>
                        ))}
                    </SimpleGrid>
                    {hasNextPage && (
                        <Button onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
                            {isFetchingNextPage ? translate("profile.loadingMore","Loading more...") : translate("profile.loadMore","Load more")}
                        </Button>
                    )}
                </>}
                {allPages.length === 0 && <Text size="sm">{translate("profile.featuredDesc","Complete orders to be able to select videos for your booking page")}</Text>} 
            </Stack>
        </Modal>
    )
}
