import { HttpError, IResourceComponentsProps, useGetIdentity, useGo, useOne, useTranslate } from "@refinedev/core";
import { Button, Center, Grid, Group, LoadingOverlay, Paper, Stack, Stepper, Title, Text } from "@mantine/core"
import { ITalent } from "interfaces";
import { OrderCustomList } from "components/orders/orderCustomList";
import { FRONTEND_URL } from "../../constants";
import { IconRefresh } from "@tabler/icons";

export const Dashboard: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { data: identity } = useGetIdentity<{ talentId: number }>();
    const { data, isLoading } = useOne<ITalent, HttpError>({
        resource: "talents",
        metaData: {
            populate: ["profileOrders"],
        },
        id: identity?.talentId,
    });

    const talent = data?.data;

    const currentStep: () => number = () => {
        if (talent?.image === null || talent?.description === null || talent?.price === null || talent?.videoURL === null) {
            return 0
        }
        if (talent?.activateRequestAccepted === null) {
            return 1
        }
        if (talent?.activateRequestAccepted === false) {
            return 2
        }
        return 0
    };
    const go = useGo();

    const handleReload = () => {
        window.location.reload();
    };



    return (<Center>
        <LoadingOverlay visible={isLoading ?? false} overlayOpacity={1} />
        {talent?.activateRequestAccepted !== true &&
            <Paper shadow="xs" p="md" >
                <Stack spacing="xl" px={{ base: 10, sm: 80 }}>
                    <Stack spacing={5}>
                        {currentStep() < 2 &&
                            <>
                                <Title order={4} pl={{ base: 0, sm: 30 }}>{translate("dashboard.boarding.welcome", "Welcome")} <i>{talent?.name}</i>,</Title>
                                <Title order={3} pl={{ base: 10, sm: 35 }}>{translate("dashboard.boarding.getstarted", "let's get things started!")}</Title>
                            </>
                        }
                        {currentStep() === 2 &&
                            <>
                                <Title order={4} pl={{ base: 0, sm: 30 }}>{translate("dashboard.boarding.thanks", "Thanks")} <i>{talent?.name}</i>,</Title>
                                <Title order={3} pl={{ base: 10, sm: 35 }}>{translate("dashboard.boarding.waitForReply", "let's wait for BV TV team reply!")}</Title>
                                <Text pl={{ base: 10, sm: 35 }}>{translate("dashboard.boarding.waitForReplyDesc", "The registration is now under review, our team will get back soon via email after revision")}</Text>
                            </>
                        }
                    </Stack>
                    <Stepper active={currentStep()} orientation="vertical" size="md" pl={{ base: 0, sm: 50 }}>
                        <Stepper.Step label={translate("dashboard.boarding.setup", "Setup Profile")} description={translate("dashboard.boarding.setupDesc", "Upload profile picture, write description and set video price")} />
                        <Stepper.Step label={translate("dashboard.boarding.request", "Request Activation  rom BV TV team")} description={translate("dashboard.boarding.requestDesc", "Your profile looks all good")} />
                        <Stepper.Step label={translate("dashboard.boarding.wait", "Wait for BV TV team reply")} description={translate("dashboard.boarding.waitDesc", "Your profile will be reviewed")} />
                        <Stepper.Step label={translate("dashboard.boarding.activated", "Activated Profile")} description={translate("dashboard.boarding.activatedDesc", "You are all set for bookings")} />
                    </Stepper>
                    {currentStep() < 2 &&
                        <Center>
                            <Button px={30} onClick={() => { go({ to: "/profile" }) }}>
                                {translate("dashboard.boarding.gotoProfile", "Go to your Profile Page")}
                            </Button>
                        </Center>
                    }
                </Stack>
            </Paper>
        }
        {talent?.activateRequestAccepted === true &&
            <Grid >
                <Grid.Col>
                    <Group position="right">
                        <Text size="sm" fw={200}>{translate("dashboard.hello", "Hello")} <b>{talent?.name ?? ""}</b>, {translate("dashboard.welcome", "welcome to BV TV Studio!")}</Text>
                    </Group>
                </Grid.Col>
                {talent?.active === false &&
                    <Grid.Col>
                        <Paper shadow="xs" p="md">
                            <Group>
                                <Text size="sm" pr={10}>{translate("dashboard.inactive", "Your profile is currently inactive")}</Text>
                                <Button px={20} variant="outline" onClick={() => { go({ to: "/profile" }) }}>
                                    {translate("dashboard.activate", "Activate on Profile Page")}
                                </Button>
                            </Group>
                        </Paper>
                    </Grid.Col>
                }
                <Grid.Col>
                    <Paper shadow="xs" p="md">
                        <Group position="apart">
                            <Group spacing={10}>
                                <Text size="xs">{translate("dashboard.bookingPage", "Booking page")}:</Text>
                                <Text size="md">{talent?.viewCount ?? 0} {translate("dashboard.views", "views")}</Text>
                                <Button size="xs" variant="outline" compact onClick={() => window.open(`${FRONTEND_URL}/${talent?.slug}`, '_blank')}>
                                    {translate("dashboard.shareBookingPage", "Share with everyone")}
                                </Button>
                            </Group>
                            {/*
                            <Group spacing={2}>
                                <Text size="sm">Sum Income:</Text>
                                <Text size="md">€42</Text>
                            </Group>
                            <Text size="xs">Last 30 days: €12</Text> */}
                            <Button variant="subtle" leftIcon={<IconRefresh />} onClick={handleReload}>
                                {translate("dashboard.refresh", "Refresh")}
                            </Button>
                        </Group>
                    </Paper>
                </Grid.Col>
                <Grid.Col sm={6}>
                    <OrderCustomList title={translate("dashboard.newOrdersListTitle", "New Orders")} statusValues={["new"]} itemsPerPage={5} sorter="dueDate" order="asc" />
                </Grid.Col>
                <Grid.Col sm={6}>
                    <OrderCustomList title={translate("dashboard.ordersInProgressListTitle", "Orders in progress")} statusValues={["inprogress"]} itemsPerPage={5} sorter="dueDate" order="asc" />
                </Grid.Col>
            </Grid>
        }
    </Center>)
}