import React from "react";
import {
  ForgotPasswordPageProps,
  ForgotPasswordFormTypes,
  useRouterType,
  useLink,
  useTranslate,
  useRouterContext,
  useForgotPassword,
  useNotification,
} from "@refinedev/core";
import { FormContext, ThemedTitleV2 } from "@refinedev/mantine";
import { FormPropsType } from "../index";
import {
  layoutStyles,
  cardStyles,
  titleStyles,
  pageTitleStyles,
} from "./styles";
import {
  Box,
  Card,
  Space,
  TextInput,
  Title,
  Anchor,
  Button,
  Text,
  BoxProps,
  CardProps,
  Group,
  useMantineTheme,
  Stack,
} from "@mantine/core";
import { AppIcon } from "components/layout/appIcon";
import { LanguageMenu } from "components/layout/languageMenu";

type ResetPassworProps = ForgotPasswordPageProps<
  BoxProps,
  CardProps,
  FormPropsType
>;

/**
 * The forgotPassword type is a page that allows users to reset their passwords. You can use this page to reset your password.
 * @see {@link https://refine.dev/docs/api-reference/mantine/components/mantine-auth-page/#forgot-password} for more details.
 */
export const ForgotPasswordPage: React.FC<ResetPassworProps> = ({
  loginLink,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
  title,
}) => {
  const theme = useMantineTheme();
  const { useForm, FormProvider } = FormContext;
  const { onSubmit: onSubmitProp, ...useFormProps } = formProps || {};
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value: any) =>
        /^\S+@\S+$/.test(value)
          ? null
          : translate(
            "forgotPassword.invalidEmail",
            "Invalid email address"
          ),
    },
    ...useFormProps,
  });
  const { getInputProps, onSubmit } = form;

  const { mutate: forgotPassword, isLoading } =
    useForgotPassword<ForgotPasswordFormTypes>();

  const { open } = useNotification();
  

  const PageTitle =
    title === false ? null : (
      <div style={pageTitleStyles}>
        {title ?? <ThemedTitleV2 icon={<AppIcon />} text={translate("common.title", "BV TV Studio")} collapsed={false} />}
      </div>
    );

  const CardContent = (
    <Card style={cardStyles} {...(contentProps ?? {})}>
      <Title
        style={titleStyles}
        color={theme.colorScheme === "dark" ? "brand.5" : "brand.8"}
      >
        {translate("forgotPassword.title", "Forgot your password?")}
      </Title>
      <Space h="lg" />
      <FormProvider form={form}>
        <form
          onSubmit={onSubmit((values: any) => {
            if (onSubmitProp) {
              return onSubmitProp(values);
            }
            return forgotPassword(values,
              {
                onSuccess: (data) => {
                  if (!data.success) {
                    open?.({
                      type: "error",
                      message: translate("forgotPassword.resetFailed", "Forgot your password?"),
                    });
                  } else {
                    open?.({
                      type: "success",
                      message: translate("forgotPassword.resetSuccess", "Password reset - Check your email for further instructions"),
                      description: "",
                    });
                  }
                },
              },
            );
          })}
        >
          <TextInput withAsterisk
            label={translate("forgotPassword.email", "Email")}
            placeholder={translate(
              "forgotPassword.email",
              "Email"
            )}
            {...getInputProps("email")}
          />

          {loginLink ?? (
            <Group mt="md" position={loginLink ? "left" : "right"}>
              <Text size="xs">
                {translate(
                  "forgotPassword.haveAccount",
                  "Have an account?"
                )}{" "}
                <Anchor component={ActiveLink as any} to="/login" weight={700}>
                  {translate("forgotPassword.signin", "Sign in")}
                </Anchor>
              </Text>
            </Group>
          )}
          <Button mt="lg" fullWidth size="md" type="submit" loading={isLoading}>
            {translate(
              "forgotPassword.submit",
              "Send reset instructions"
            )}
          </Button>
        </form>
      </FormProvider>
    </Card>
  );

  return (
    <Box style={layoutStyles} {...(wrapperProps ?? {})}>
      {renderContent ? (
        renderContent(CardContent, PageTitle)
      ) : (
        <Group spacing={5} position="center" my={20} mx={50}>
          <Box px={{ base: 0, xs: 40 }} pb={5}>
            <Stack align="center">
              <LanguageMenu />
              {PageTitle}
            </Stack>
          </Box>
          {CardContent}
        </Group>
      )}
    </Box>
  );
};
