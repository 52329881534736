import React, { useEffect } from "react";
import { ActionIcon, Badge, Button, Card, Center, Group, LoadingOverlay, Paper, Stack, Text } from "@mantine/core";
import { HttpError, IResourceComponentsProps, useBack, useOne, useTranslate, useUpdate } from "@refinedev/core";
import { DateField } from "@refinedev/mantine";
import { IOrder } from "interfaces";
import { useParams } from "react-router-dom";
import { VideoPlayer } from "components/video/videoPlayer";
import { VideoUploader } from "components/video/videoUploader";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowLeft } from "@tabler/icons";


export const OrderEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();  
  const [opened, handlers] = useDisclosure(false);
  const { open } = handlers
  const { id: orderId } = useParams();
  const { mutate } = useUpdate();
  const { data, isLoading } = useOne<IOrder, HttpError>({
    resource: "orders", id: orderId!,
    meta: {
      populate: ["talent"],
    }
  });
  const order = data?.data
  const isVideoUnavailable = typeof order?.videoURL === 'undefined' || order?.videoURL === '' || order?.videoURL === null;

  // mark order automatically inprogress when first editing
  useEffect(() => {
    if (data?.data.status === "new") {
      mutate({
        resource: "orders",
        values: {
          status: "inprogress",
        },
        id: orderId!,
        successNotification: (data, values, resource) => {
          return {
            message: translate("orders.createVideoMessage", "Create a video and mark order COMPLETE, when all looks fine."),
            description: translate("orders.createVideoMessageDesc", "Order status changed to INPROGRESS."),
            type: "success",
          }
        },
      });
    }
  }, [data, orderId, mutate, translate]);

  const markComplete = () => {
    mutate({
      resource: "orders",
      values: {
        status: "complete",
      },
      id: orderId!,
      successNotification: (data, values, resource) => {
        return {
          message: translate("orders.markCompleteMessage", "Hurray"),
          description: translate("orders.markCompleteMessageDesc","Order marked COMPLETE."),
          type: "success",
        }
      },
    })

    if (order && order.talent) {
      var completeOrderCountIncrease = 0
      if (order.talent.completeOrderCount) {
        completeOrderCountIncrease = order.talent.completeOrderCount + 1
      } else {
        completeOrderCountIncrease = 1
      }

      mutate({
        resource: "talents",
        values: {
          completeOrderCount: completeOrderCountIncrease,
        },
        id: order?.talent.id,
        successNotification: (data, values, resource) => {
          return {
            message: translate("orders.markCompleteEnhanceMessage", "Completing orders enhance your visibility in search results and listings."),
            description: translate("orders.markCompleteEnhanceMessageDesc","Increased popularity"),
            type: "success",
          }
        },        
      });
    }
  }
  
  const back = useBack();

  return (<Center>
    <LoadingOverlay visible={isLoading ?? false} overlayOpacity={1} />
    <Paper shadow="xs" p="md" >
      <Stack>
        <Group>
          <ActionIcon variant="light" size="md" onClick={() => back()}>
            <IconArrowLeft />
          </ActionIcon>
          <Text size="xs" >#{order?.orderID}</Text>
          <Badge size="xs">{translate(`orders.statusValues.${order?.status}`, order?.status)}</Badge>
          {order?.status !== "complete" &&
            <Group>
              <Button onClick={open}>
                {translate("orders.recordVideo", "Record Video")}
              </Button>
              <Button variant="outline"
                disabled={isVideoUnavailable}
                onClick={markComplete}
              >
                {translate("orders.markComplete", "Mark Complete")}
              </Button>
            </Group>
          }
        </Group>
        <Card withBorder radius="sm">
          <Group position="center">
            {!isVideoUnavailable &&
              <VideoPlayer videoURL={order.videoURL} />
            }
            <Stack>
              <Group spacing={20}>
                <Stack spacing={0}>
                  <Text fz="xs" tt="uppercase" fw={700} c="dimmed">{translate("orders.from", "From")}</Text>
                  <Text fz="lg" fw={500}>{order?.from}</Text>
                </Stack>
                <Stack spacing={0}>
                  <Text fz="xs" tt="uppercase" fw={700} c="dimmed">{translate("orders.to", "To")}</Text>
                  <Text fz="lg" fw={500}>{order?.to}</Text>
                </Stack>
              </Group>
              <Group spacing={20}>
                <Stack spacing={0}>
                  <Text fz="xs" tt="uppercase" fw={700} c="dimmed">{translate("orders.occasion", "Occasion")}</Text>
                  <Badge mt={7} size="sm" variant="outline">{translate(`orders.occasionValues.${order?.occasion}`, order?.occasion)}</Badge>
                </Stack>
                <Stack spacing={0}>
                  <Text fz="xs" tt="uppercase" fw={700} c="dimmed">{translate("orders.dueDate", "Due Date")}</Text>
                  <DateField value={order?.dueDate} format="LL" />
                </Stack>
                <Stack spacing={0}>
                  <Text fz="xs" tt="uppercase" fw={700} c="dimmed">{translate("orders.orderDate", "Order Date")}</Text>
                  <DateField value={order?.createdAt} format="LL" />
                </Stack>
              </Group>
              <Stack spacing={0} maw={300}>
                <Text fz="xs" tt="uppercase" fw={700} c="dimmed">{translate("orders.request", "Request")}</Text>
                <Text fz="lg" fw={500} maw={650}>{order?.requestDescription}</Text>
              </Stack>
            </Stack>
          </Group>
        </Card>
      </Stack>
    </Paper>
    <VideoUploader
      apiResource="orders"
      apiID={orderId!}
      successMessage={translate("orders.markCompleteSuccess", "Mark complete or record again if you don't like it")}
      opened={opened}
      handlers={handlers}
    />
  </Center>
  )
};
