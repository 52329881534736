import React from "react";
import {
  UpdatePasswordPageProps,
  UpdatePasswordFormTypes,
  useUpdatePassword,
  useTranslate,
  useNotification,
} from "@refinedev/core";
import { FormContext, ThemedTitleV2 } from "@refinedev/mantine";
import { FormPropsType } from "../index";
import {
  layoutStyles,
  cardStyles,
  titleStyles,
  pageTitleStyles,
} from "./styles";
import {
  Box,
  Card,
  Space,
  TextInput,
  Title,
  Button,
  BoxProps,
  CardProps,
  useMantineTheme,
  Group,
  Stack,
} from "@mantine/core";
import { AppIcon } from "components/layout/appIcon";
import { LanguageMenu } from "components/layout/languageMenu";

type UpdatePassworProps = UpdatePasswordPageProps<
  BoxProps,
  CardProps,
  FormPropsType
>;

/**
 * The updatePassword type is the page used to update the password of the user.
 * @see {@link https://refine.dev/docs/api-reference/mantine/components/mantine-auth-page/#update-password} for more details.
 */
export const UpdatePasswordPage: React.FC<UpdatePassworProps> = ({
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
  title,
}) => {
  const theme = useMantineTheme();
  const { useForm, FormProvider } = FormContext;
  const { onSubmit: onSubmitProp, ...useFormProps } = formProps || {};
  const translate = useTranslate();

  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate: {
      password: (value: any) => {
        if (!value) {
          return translate(
            "register.passwordRequired",
            "Password is required"
          );
        }
        if (value.length < 8) {
          return translate(
            "register.passwordLenght",
            "Password must be at least 8 characters long"
          );
        }
        if (!/[A-Z]/.test(value)) {
          return translate(
            "register.passwordContainLetter",
            "Password must contain at least one uppercase letter"
          );
        }
        if (!/\d/.test(value)) {
          return translate(
            "register.passwordContainNumber",
            "Password must contain at least one number"
          );
        }
        return null;
      },
      confirmPassword: (value: any, values: any) =>
        value !== values.password
          ? translate(
              "updatePassword.confirmPasswordNotMatch",
              "Passwords do not match"
            )
          : null,
    },
    ...useFormProps,
  });
  const { getInputProps, onSubmit } = form;

  const { mutate: updatePassword, isLoading } =
    useUpdatePassword<UpdatePasswordFormTypes>();

  const PageTitle =
    title === false ? null : (
      <div style={pageTitleStyles}>
        {title ?? <ThemedTitleV2 icon={<AppIcon />} text={translate("common.title", "BV TV Studio")} collapsed={false} />}
      </div>
    );

  const { open } = useNotification();

  const CardContent = (
    <Card style={cardStyles} {...(contentProps ?? {})}>
      <Title
        style={titleStyles}
        color={theme.colorScheme === "dark" ? "brand.5" : "brand.8"}
      >
        {translate("updatePassword.title", "Set New Password")}
      </Title>
      <Space h="lg" />
      <FormProvider form={form}>
        <form
          onSubmit={onSubmit((values: any) => {
            if (onSubmitProp) {
              return onSubmitProp(values);
            }
            return updatePassword(values,
              {
                onSuccess: (data) => {
                  if (!data.success) {
                    open?.({
                      type: "error",
                      message: translate("updatePassword.updateFailed", "Password update failed"),
                    });
                  } else {
                    open?.({
                      type: "success",
                      message: translate("updatePassword.updateSuccess", "Password updated"),
                    });
                  }
                },
              },
            );
          })}
        >
          <TextInput
            type="password"
            label={translate(
              "updatePassword.password",
              "New Password"
            )}
            placeholder="●●●●●●●●"
            {...getInputProps("password")}
          />
          <TextInput
            mt="md"
            type="password"
            label={translate(
              "updatePassword.confirmPassword",
              "Confirm New Password"
            )}
            placeholder="●●●●●●●●"
            {...getInputProps("confirmPassword")}
          />
          <Button mt="lg" fullWidth size="md" type="submit" loading={isLoading}>
            {translate("updatePassword.update", "Update")}
          </Button>
        </form>
      </FormProvider>
    </Card>
  );

  return (
    <Box style={layoutStyles} {...(wrapperProps ?? {})}>
      {renderContent ? (
        renderContent(CardContent, PageTitle)
      ) : (
        <Group spacing={5} position="center" my={20} mx={50}>
          <Box px={{ base: 0, xs: 40 }} pb={5}>
            <Stack align="center">
              <LanguageMenu />
              {PageTitle}
            </Stack>
          </Box>
          {CardContent}
        </Group>
      )}
    </Box>
  );
};
