import { Text, Center, Paper, ScrollArea, Table, Title, Pagination, Badge, } from "@mantine/core";
import { useGetIdentity, useTranslate } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { EditButton, DateField } from "@refinedev/mantine";
import { ColumnDef } from "@tanstack/table-core";
import { IOrder } from "interfaces";
import React from "react";
import { flexRender } from "@tanstack/react-table";
import { IconVideo } from "@tabler/icons";

interface OrderCustomListProps {
    title: string
    statusValues: Array<string>
    itemsPerPage: number
    noStatusRow?: boolean
    sorter: string
    order: "asc" | "desc"
}

export const OrderCustomList: React.FC<OrderCustomListProps> = ({ title, statusValues, itemsPerPage, noStatusRow = true, sorter, order }) => {
    const translate = useTranslate(); 
    const { data: identity } = useGetIdentity<{ talentId: number }>();

    const columns = React.useMemo<ColumnDef<IOrder>[]>(
        () => [
            {
                id: "actions",
                accessorKey: "id",
                header: "",
                enableColumnFilter: false,
                enableSorting: false,
                cell: function render({ getValue }) {
                    return (
                        <Center>
                            <EditButton size="sm" leftIcon={<IconVideo />}
                                recordItemId={getValue() as string}
                                resource="orders"
                            >
                                {translate("orders.view", "View")}
                            </EditButton>
                        </Center>
                    );
                },
                size: 50,
                minSize: 50
            },
            {
                id: "dueDate",
                accessorKey: "dueDate",
                header: translate("orders.dueDate", "Due Date"),
                cell: function render({ getValue }) {

                    const dueDateValue = getValue<any>()
                    const dueDate = new Date(dueDateValue);
                    const currentDate = new Date()
                    const dateColor = dueDate < currentDate ? "red" : "primary"
            
                    return <DateField value={dueDate} format="MMM D" color={noStatusRow ? dateColor : "primary"} />
                },
                enableResizing: false,
                maxSize: 130
            },
            {
                id: "createdAt",
                accessorKey: "createdAt",
                header: translate("orders.orderDate", "Order Date"),
                cell: function render({ getValue }) {
                    return <DateField value={getValue<any>()} format="MMM D" w={50} />
                },
                enableResizing: false,
                maxSize: 130
            },
            {
                id: "status",
                accessorKey: "status",
                header: translate("orders.status", "Status"),
                cell: function render({ getValue }) {
                    return <Badge size="sm">{translate(`orders.statusValues.${getValue<any>()}`, getValue<any>())}</Badge>
                },
            },
            {
                id: "occasion",
                accessorKey: "occasion",
                header: translate("orders.occasion", "Occasion"),
                cell: function render({ getValue }) {
                    return <Badge size="sm" variant="outline">{translate(`orders.occasionValues.${getValue<any>()}`, getValue<any>())}</Badge>
                },
            },
            {
                id: "from",
                accessorKey: "from",
                header: translate("orders.from", "From"),
            },
            {
                id: "to",
                accessorKey: "to",
                header: translate("orders.to", "To"),
            },
        ],
        [noStatusRow, translate],
    );
    const {
        getHeaderGroups,
        getRowModel,
        refineCore: {
            setCurrent,
            pageCount,
            pageSize,
            current,
            tableQueryResult,
        },
    } = useTable({
        columns,
        refineCoreProps: {
            resource: "orders",
            sorters: {
                permanent: [
                    {
                        field: sorter,
                        order: order,
                    },
                ],
            },
            filters: {
                permanent: [
                    {
                        field: "talent.id",
                        operator: "eq",
                        value: identity?.talentId !== undefined ? identity?.talentId : 0,
                    },
                    {
                        field: "status",
                        operator: "in",
                        value: statusValues,
                    },
                ],
            },
            metaData: {
                populate: ["talent"],
            },
            pagination: {
                pageSize: itemsPerPage
            }
        },
    });

    return (
        <Paper shadow="xs" p="md">
            <Title order={3}>{title}</Title>
            {tableQueryResult.data && tableQueryResult.data?.total > 0 &&
                <>
                    <ScrollArea w={{ base: '85vw', sm: '100%' }}>
                        <Table verticalSpacing="md">
                            <thead>
                                {getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            if (noStatusRow && header.getContext().column.id === "status") {
                                                return null
                                            }

                                            return (
                                                <th key={header.id}>
                                                    {!header.isPlaceholder &&
                                                        flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext(),
                                                        )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {getRowModel().rows.map((row) => {
                                    return (
                                        <tr key={row.id} style={{whiteSpace:"nowrap"}}>
                                            {row.getVisibleCells().map((cell) => {
                                                if (noStatusRow && cell.getContext().column.id === "status") {
                                                    return null
                                                }

                                                return (
                                                    <td key={cell.id}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext(),
                                                        )}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </ScrollArea>
                    <br />
                    {tableQueryResult.data && tableQueryResult.data?.total > pageSize &&
                        <Pagination
                            position="center"
                            total={pageCount}
                            page={current}
                            onChange={setCurrent}
                        />
                    }
                </>
            }
            {tableQueryResult.data && tableQueryResult.data?.total === 0 &&
                <Center>
                    <Text p={20}>
                        {translate("orders.noOrdersFound", "No orders found")}
                    </Text>
                </Center>
            }
        </Paper>
    )
}