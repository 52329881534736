import { Text, ActionIcon, Burger, Group, Header as MantineHeader, Sx, useMantineColorScheme, useMantineTheme, createStyles, Drawer, Box, Divider, ScrollArea, NavLink } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useActiveAuthProvider, useLogout, useTranslate, useWarnAboutChange } from "@refinedev/core";
import { RefineThemedLayoutV2HeaderProps, ThemedTitleV2, } from "@refinedev/mantine";
import { IconUserCircle, IconListDetails, IconLayoutDashboard, IconMoonStars, IconSun, IconLogout } from "@tabler/icons";
import { AppIcon } from "components/layout/appIcon";
import { Link } from "react-router-dom";
import { LanguageMenu } from "./languageMenu";
//import i18n from "i18n";


const useStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan('sm')]: {
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }),
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
}));

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  isSticky,
}) => {
  const { classes } = useStyles();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

  const theme = useMantineTheme();

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  const borderColor = dark ? theme.colors.dark[6] : theme.colors.gray[2];

  const authProvider = useActiveAuthProvider();
  const { warnWhen, setWarnWhen } = useWarnAboutChange();
  const t = useTranslate();
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  let stickyStyles: Sx = {};
  if (isSticky) {
    stickyStyles = {
      position: `sticky`,
      top: 0,
      zIndex: 1,
    };
  }

  const handleLogout = () => {
    if (warnWhen) {
      const confirm = window.confirm(
        t(
          "warnWhenUnsavedChanges",
          "Are you sure you want to leave? You have unsaved changes.",
        ),
      );

      if (confirm) {
        setWarnWhen(false);
        mutateLogout();
      }
    } else {
      mutateLogout();
    }
  };
  const translate = useTranslate();
  return (
    <MantineHeader
      zIndex={199}
      height={50}
      //py={6}
      px="md"
      sx={{
        borderBottom: `1px solid ${borderColor}`,
        ...stickyStyles,
      }}
    >
      <Group position="apart" sx={{ height: '100%' }}>
        <Group>
          <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
          <ThemedTitleV2
            collapsed={false}
            text={translate("common.title", "BV TV Studio")}
            icon={<AppIcon />}
            wrapperStyles={{ whiteSpace: 'nowrap' }}
          />
        </Group>
        <Group sx={{ height: '100%' }} spacing={0} className={classes.hiddenMobile}>
          <Box
            component={Link as any}
            to="/"
            className={classes.link}
          >
            <IconLayoutDashboard />
            <Text pl={10}>{translate("header.dashboard", "Dashboard")}</Text>
          </Box>
          <Box
            component={Link as any}
            to="/orders"
            className={classes.link}
          >
            <IconListDetails />
            <Text pl={10}>{translate("header.orders", "Orders")}</Text>
          </Box>
          <Box
            component={Link as any}
            to="/profile"
            className={classes.link}
          >
            <IconUserCircle />
            <Text pl={10}>{translate("header.profile", "Profile")}</Text>
          </Box>
        </Group>
        <Group>
          <LanguageMenu/>
          <ActionIcon
            variant="outline"
            color={dark ? "yellow" : "primary"}
            onClick={() => toggleColorScheme()}
            title={translate("header.colorToggle", "Toggle color scheme")}
          >
            {dark ? <IconSun size={18} /> : <IconMoonStars size={18} />}
          </ActionIcon>

          <ActionIcon
            variant="outline"
            onClick={handleLogout}
            title={translate("header.logout", "Logout")}
            className={classes.hiddenMobile}
          >
            <IconLogout size={18} />
          </ActionIcon>
        </Group>
      </Group>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="sm"
        title={<Box onClick={closeDrawer} mah={50}><ThemedTitleV2
          collapsed={false}
          text={translate("common.title", "BV TV Studio")}
          icon={<AppIcon />}
          wrapperStyles={{ whiteSpace: 'nowrap' }}
        /></Box>}
        trapFocus={false}
        //position="right"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - 50)`} mx="-md">
          <Divider color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />
          <NavLink
            key="dashboard"
            label={translate("header.dashboard", "Dashboard")}
            icon={<IconLayoutDashboard />}
            component={Link as any}
            to="/"
            className={classes.link}
            onClick={closeDrawer}
          />
          <NavLink
            key="ordres"
            label={translate("header.orders", "Orders")}
            icon={<IconListDetails />}
            component={Link as any}
            to="/orders"
            className={classes.link}
            onClick={closeDrawer}
          />
          <NavLink
            key="profile"
            label={translate("header.profile", "Profile")}
            icon={<IconUserCircle />}
            component={Link as any}
            to="/profile"
            className={classes.link}
            onClick={closeDrawer}
          />
          <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />
          <NavLink
            key="logout"
            label={translate("header.logout", "Logout")}
            icon={<IconLogout />}
            onClick={handleLogout}
            className={classes.link}
          />
        </ScrollArea>
      </Drawer>
    </MantineHeader>
  );
};
