import { useRef, useState } from "react";
import VideoRecorder from "./VideoRecorder";
import { Button, Text, CloseButton, Group, Modal, Stack } from "@mantine/core";
import { useUpdate, useNotification, useTranslate } from "@refinedev/core";
import React from "react";
import { IconLoader, IconUpload } from "@tabler/icons";

interface VideoUploaderProps {
    apiResource: string;
    apiID: string;
    successMessage: string;
    opened: boolean
    handlers: {
        open: () => void;
        close: () => void;
        toggle: () => void;
    }
}

export const VideoUploader: React.FC<VideoUploaderProps> = ({ apiResource, apiID, successMessage, opened, handlers }) => {
    const translate = useTranslate(); 
    const { close } = handlers

    const videoRecorderRef = useRef(null);
    const videoPlayerRef = useRef(null);
    const [deviceReady, setDeviceReady] = useState(false);
    const [deviceLoading, setDeviceLoading] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [doneRecording, setDoneRecording] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const { mutate } = useUpdate();
    const { open: openNotification } = useNotification();
    
    const uploadVideo = async (blob: Blob) => {
        var formdata = new FormData();

        formdata.append("file", blob);
        formdata.append("cloud_name", "kavics");
        formdata.append("upload_preset", "unsigbvtv");
        //formdata.append("public_id", `${talent.slug}-${Date.now()}`);
        // @ts-ignore
        formdata.append("public_id", blob.name.split('.').slice(0, -1).join('.'));

        setIsUploading(true)
        let res = await fetch(
            "https://api.cloudinary.com/v1_1/kavics/auto/upload",
            {
                method: "post",
                mode: "cors",
                body: formdata,
            }
        );

        let json = await res.json();
        //console.log(JSON.stringify(json.secure_url));
        setIsUploading(false)
        
        var url = json.secure_url
        var parts = url.split('.');
        parts[parts.length - 1] = 'mp4';
        var modifiedUrl = parts.join('.');

        mutate({
            resource: apiResource,
            values: {
                videoURL: modifiedUrl,
            },
            id: apiID,
            successNotification: (data, values, resource) => {
                return {
                    message: successMessage,
                    type: "success",
                }
            },
        });

        setDeviceReady(false)
        setIsRecording(false)
        setIsUploading(false)
        setDoneRecording(false)
        close()
    };

    // @ts-ignore
    const handleVideoRecorderReady = (videoRecorder, videoPlayer) => {
        videoRecorderRef.current = videoRecorder
        videoPlayerRef.current = videoPlayer

        videoRecorder.on('deviceReady', () => {
            //console.log('Device is ready!');
            setDeviceReady(true)
            setDeviceLoading(false)
        });

        videoRecorder.on('startRecord', () => {
            //console.log('Started recording!');
            setIsRecording(true);
        });

        videoRecorder.on('stopRecord', () => {
            //console.log('Stopped recording!');
            setIsRecording(false);
        });

        videoRecorder.on('finishRecord', () => {
            //console.log('Finished recording: ', videoRecorder.recordedData);
            setDoneRecording(true)
            videoPlayer.play()
        });

        // Error handling
        // @ts-ignore
        videoRecorder.on('error', (error) => {
            console.error('Error on video recorder', error);
            openNotification?.({
                type: "error",
                message: translate("videouploader.recordErrorDesc", "Something went wrong during video recording"),
                description: translate("videouploader.recordError", "Video recording error"),
            });
        });

        videoRecorder.on('deviceError', () => {
            console.error('device error:', videoRecorder.deviceErrorCode);
            openNotification?.({
                type: "error",
                message: translate("videouploader.deviceErrorDesc","Something is wrong with your device"),
                description: translate("videouploader.recordError", "Video recording error"),
            });
        });
    };


    return (
        <Modal
            opened={opened}
            onClose={close}
            title={<React.Fragment />}
            fullScreen={true}
            withCloseButton={false}
            padding={20}
            size="auto"
            trapFocus={false}
        >
            <Stack align="center" justify="flex-start">
                <Group position="right" w={300} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    {!deviceReady &&
                        <Button mr={10} px={30} variant="outline" loading={deviceLoading} onClick={() => {
                            // @ts-ignore
                            videoRecorderRef.current?.record().getDevice()
                            setDeviceLoading(true)
                        }} >
                            {translate("videouploader.enable","Enable Camera")}
                        </Button>
                    }
                    {deviceReady &&
                        <>
                            {!isUploading &&
                                <Button mr={doneRecording ? 0 : 5} px={doneRecording ? 5 : 60}
                                    onClick={() => {
                                        setDoneRecording(false)
                                        // @ts-ignore
                                        isRecording ? videoRecorderRef.current?.record().stop() : videoRecorderRef.current?.record().start();
                                    }}
                                >
                                    {isRecording ? translate("videouploader.stop","Stop Recording") : doneRecording ? translate("videouploader.rerecord","Re-record") : translate("videouploader.record","Record")}
                                </Button>
                            }
                            {doneRecording && 
                            <Button  mr={isUploading ? 5 : 0} px={40}
                                onClick={() => {
                                    // @ts-ignore
                                    uploadVideo(videoRecorderRef.current?.recordedData)
                                }}
                                variant = {isUploading ? "default": "outline"}
                                disabled={(!doneRecording || isUploading)}
                                loading={isUploading}
                            >
                                {!doneRecording ? translate("videouploader.upload","Upload") : (isUploading ? translate("videouploader.uploading","Uploading...") :  translate("videouploader.upload","Upload"))}
                            </Button>
                            }
                        </>
                    }
                    <CloseButton title="Close" w={40} iconSize={20} mr={10}
                        onClick={() => {
                            setDeviceReady(false)
                            setIsRecording(false)
                            setIsUploading(false)
                            setDoneRecording(false)
                            close()
                        }}
                    />
                </Group>
                {!isUploading && 
                <VideoRecorder onReady={handleVideoRecorderReady} />
                }
                {deviceReady && !isUploading && 
                <Text size="xs">{translate("videouploader.hint","Hint: Use controlls to play / record video")}</Text>
                }
                {isUploading && 
                <Stack h={400} pt={50} align="center">
                    <IconLoader size={60}/>
                    <IconUpload size={150}/>
                    <Text fw={200} size="xl">{translate("videouploader.longer","Upload might take longer time")}</Text>
                    <Text fw={200} size="sm">{translate("videouploader.wait","Please wait...")}</Text>
                </Stack>
                }
            </Stack>
        </Modal>
    )
}