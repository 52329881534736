import React from "react";
import { ThemedTitleV2 } from "@refinedev/mantine";
import {
  layoutStyles,
  cardStyles,
  titleStyles,
  pageTitleStyles,
} from "./styles";
import {
  Box,
  Card,
  Space,
  Title,
  Text,
  Group,
  useMantineTheme,
  Stack,
} from "@mantine/core";
import { AppIcon } from "components/layout/appIcon";
import { LanguageMenu } from "components/layout/languageMenu";
import { useTranslate } from "@refinedev/core";

export const UnderReviewPage: React.FC = () => {
  const theme = useMantineTheme();
  const translate = useTranslate();

  const PageTitle = (
    <div style={pageTitleStyles}>
      <ThemedTitleV2 icon={<AppIcon />} text={translate("common.title", "BV TV Studio")} collapsed={false} />
    </div>
  );

  const CardContent = (
    <Card style={cardStyles}>
      <Title
        style={titleStyles}
        color={theme.colorScheme === "dark" ? "brand.5" : "brand.8"}
      >
        {translate("underreview.thanks", "Thank you for registering!")}
      </Title>
      <Space h="lg" />
      <Text ta="center">
        {translate("underreview.info", "Our team will review your request soon and email you with further information.")}
      </Text>
    </Card>
  );

  return (
    <Box style={layoutStyles}>
        <Group spacing={5} position="center" my={20} mx={50}>
          <Box px={{ base: 0, xs: 40 }} pb={5}>
            <Stack align="center">
              <LanguageMenu />
              {PageTitle}
            </Stack>
          </Box>
          {CardContent}
        </Group>

    </Box>
  );
};
