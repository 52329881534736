// @ts-nocheck
import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import 'webrtc-adapter';
import 'videojs-record/dist/css/videojs.record.css';
import "videojs-record/dist/videojs.record.js";
import { isMobile } from 'react-device-detect';

export const VideoJSComponent = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { onReady } = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.className = 'video-js vjs-default-skin vjs-9-16';
      videoElement.setAttribute('playsInline', "")
      videoRef.current.appendChild(videoElement);

      // options: https://collab-project.github.io/videojs-record/#/options
      // mime types: https://github.com/muaz-khan/RecordRTC
      // video options: https://w3c.github.io/mediacapture-main/#media-track-supported-constraints

      const options = {
        autoplay: true,
        controls: true,
        bigPlayButton: true,
        width: 360,
        height: 640,
        fluid: true,
        plugins: {
          record: {
            maxLength: 30,
            //videoMimeType: "video/webm",
            timeSlice: 2000,
            audio: true,
            video: {
              width: isMobile ? 640 : 360,
              height: isMobile ? 360 : 640,
              facingMode: "user"
            }
          },
        },
      };
      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      });

      // You could update an existing player in the `else` block here
      // on prop change
    } else {
      // const player = playerRef.current;
      // player.record().getDevice();
    }
    // eslint-disable-next-line
  }, [videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player style={{ width: '300px' }}>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJSComponent;