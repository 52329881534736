import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { OrderCustomList } from "components/orders/orderCustomList";
import React from "react";

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();  
  return (
      <OrderCustomList title={translate("orders.ordersListTitle", "Orders")} statusValues={["new","inprogress","complete"]} itemsPerPage={20} noStatusRow={false} sorter="createdAt" order="desc"/>
  )
};
